@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.swal2-confirm {
  background: oklch(0.697176 0.135065 172.084) !important ;
}

.swal2-confirm:hover {
  background: oklch(0.697176 0.135065 172.084) !important ;
}

.swal2-container {
  z-index: 9999;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: oklch(0.697176 0.135065 172.084);
}

.p-inputswitch-slider {
  box-shadow: none !important;
}

.p-datatable *:not(i) {
  font-weight: 400 !important;
}

.p-datatable td,
.p-datatable th {
  padding: 10px;
}


.p-datatable-thead > tr > th {
  background: white !important;
  border-bottom: 1px solid #eef1ee ;
}

.p-datatable .p-sortable-column.p-highligh,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.p-datatable .p-sortable-column:hover {
  color: #374151 !important;
}

.p-datatable-thead .p-column-title {
  font-weight: 600 !important;
}

.p-datatable .p-row-odd {
  background: oklch(0.982888 0.023996 167.122);
}

.p-datatable .p-paginator {
  border-top: 1px solid #eef1ee ;
  background: white !important;
  padding: 0px !important;
}

.p-datatable .p-dropdown {
  height: 2rem;
}
.p-datatable .p-dropdown .p-inputtext {
  padding: 5px 0px 5px 5px;
}

.p-datatable .p-dropdown .p-dropdown-trigger {
  padding: 0 !important;
  height: 30px;
}

.p-dropdown-item {
  padding: 10px;
}

.p-dropdown-item:hover {
  background: oklch(0.982888 0.023996 167.122);
}

.p-highlight {
  background: oklch(0.982888 0.023996 167.122) !important;
}

.p-highlight.p-row-odd {
  background: #ebfff6 !important;
}

.p-checkbox-box {
  border: solid 1px rgb(218, 216, 216);
}
.p-checkbox-box.p-highlight {
  background: #00b894 !important;
}

.p-focus {
  box-shadow: none !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 10px !important;
  height: 2.5rem;
}

/* DIALOG */
.p-dialog .p-dialog-header .p-dialog-header-icon:focus-visible {
  box-shadow: 0 0 0 0.2rem #00b894 !important; 
}


/* SCROLL */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #00b894; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #019275;; 
}

/* Calendar */

.p-inputtext:enabled:focus {
  box-shadow: none !important;
}